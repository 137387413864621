





















import {Component, Vue} from 'vue-property-decorator';

const OnboardingChecklist = () => import("@/components/OnboardingChecklist.vue")



@Component({
  components: {OnboardingChecklist},
})
export default class OnboardingChecklistButton extends Vue {

  openChecklist: boolean = false;

  get onboarding() {
    return this.$store.state.expanded_onboarding;
  }
  
  handleClick() {
    this.$emit('handle-checklist-click')
  }

  mounted() {
    if(this.onboarding) {
      this.openChecklist = true;
    }
  }
  
  

}
